export enum ENUM_SERVICES {
	DEPARTMENTS = 'departments',
	LASTALIFE = 'lastAlife',
	MAINMMONITOR = 'main-monitor',
	ORGANIZATIONFUNCTIONS = 'organization-functions',
	OPERATIVEFUNCTIONS = 'operative-functions',
	PERSONS = 'persons',
	REGDATA = 'regdata',
	RFIDTAGS = 'rfid-tags',
	TERMINALS = 'terminal',
	USERS = 'users',
	USERSETTINGS = 'user-settings',
	UTILS = 'utils',
};