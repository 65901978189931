import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"

export const InputChangesChecked = ({ id, type, label, formik, className, onFocus, setShowDialog, disabled, tabIndex, autoFocus, helptext, helptextcolor, handleChange, handleBlur, rowStyle, colLabels }) => {

    const isFormFieldValid = (name) => { return formik.errors[name] };
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error block mt-1">{formik.errors[id]}</small>;
    };
    const getFormHelpMessage = (name) => {
        return !isFormFieldValid(name) && helptext && <small style={{ color: helptextcolor }} className=" block" dangerouslySetInnerHTML={{ __html: helptext }} />;
    };

    const onChange = (e) => {
        formik.handleBlur(e);
        formik.handleChange(e)
        if (handleChange) { handleChange(e) }
    }

    const onBlur = (e) => {
        formik.handleBlur(e);
        if (handleBlur) { handleBlur(e) }
    }

    return (
        <div className={rowStyle ? 'flex w-full' : 'field col mr-3'}>
            <label htmlFor={id} className={rowStyle ? (colLabels || 'col-3') : 'block'}>{label}</label>
            <div className={rowStyle ? 'col' : ''}>
                <InputText key={'input-' + id}
                    tabIndex={tabIndex}
                    id={id}
                    name={id}
                    type={type}
                    readOnly={disabled}
                    disabled={disabled}
                    value={formik.values[id]}
                    onBlur={onBlur}
                    onChange={onChange}
                    autoFocus={autoFocus}
                    className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + className}
                    autoComplete="off"
                    onFocus={(event) => { if (onFocus) { onFocus(event) }; event.target.setAttribute('autocomplete', 'new-password') }} />
                {getFormErrorMessage(id)}
                {getFormHelpMessage(id)}
            </div>
        </div>
    )

}

