import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { Departments } from "../Components/Departments";
import { useMainMonitorList } from "../Persistence/MainMonitorContext";
import Headline from "../Components/Headline";
import { TerminalsMonitor } from "./TerminalsMonitor";
import { TerminalProvider } from "../Persistence/TerminalContext";


export const MainMonitor = () => {

    const departmentList = useMainMonitorList();//useDepartmentList();
    const [departments, setDepartments] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (departmentList) {
            for (let d of departmentList) {
                d.regdata = d.regdata.filter(e => e.personId);
            }
            setDepartments(departmentList.filter(e => e.regdata && e.regdata.length > 0))
        }
    }, [departmentList])

    const buttons = [
        { key: 'mainMonitorButton_1', label: 'Personen', command: () => navigate('/' + ENUM_ROUTES.PERSONADMINISTRATION) },
        { key: 'mainMonitorButton_2', label: 'Liste leeren', command: () => { } }
    ]

    return <>
        <div className={"flex flex-column border-bottom-1 gap-3 surface-border "} style={{ height: '60px' }}>
            <Headline buttons={buttons} />
        </div>
        <div className='flex'>
            <Departments departments={departments} />
            <TerminalProvider><TerminalsMonitor /></TerminalProvider>
        </div>
    </>

}