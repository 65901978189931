import { useState } from "react";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES"
import { patchData } from "../feathers"
import { getSortedList } from "../snippets"
import { ConfirmDialog } from "./ConfirmDialog";
import exportToPDF from "../Utils/exportToPdf";


export const Department = ({ department, classHeight }) => {
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [displayConfirmPrintDialog, setDisplayConfirmPrintDialog] = useState(false);

    const renderListEntries = (list) => {
        return getSortedList(list, 'name').map(e => {
            return <div>{e.name}</div>
        })
    }

    const clearRegdataList = async () => {
        const resetDate = new Date();
        for (let regdata of department.regdata) {
            regdata.resetDate = resetDate
            if (department.manageTerminalList !== 0) {
                regdata.resetOnTerminalDate = resetDate
            }
            await patchData(ENUM_SERVICES.REGDATA, regdata).catch((error) => {
                console.error('Error clearRegdataList:', regdata, error)
            })
        }
    }

    const printRegdataList = async () => {
        const list = department.regdata.map(e => { return { id: e.personId, name: e.lastname + ', ' + e.firstname } }).filter((item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        )
        await exportToPDF(department, list, new Date())

    }

    const renderConfirmDialog = () => {
        return <ConfirmDialog
            handleOnClick={() => clearRegdataList()}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Löschen'
            message={`Wollen Sie sich wirklich die Liste der Abteilung\n${department.name} mit ${department.regdata.length} Einträgen\nlöschen?`}
            title='FFW-GD'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
    }


    const renderDepartment = () => {
        const list = department.regdata.map(e => { return { id: e.personId, name: e.lastname + ', ' + e.firstname } }).filter((item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        )
        return <div className={'flex flex-column w-2 department-card ' + classHeight}>
            <div className='flex text-color px-3 p-2 w-full bg-white opacity-90 gap-2'>
                <div className='flex-grow-1'> {`${department.name} (${list.length})`}</div>
                <i className='pt-1 justify-content-end pi pi-trash ' style={{ 'fontSize': '1.2em' }} onClick={() => setDisplayConfirmDialog(true)}></i>
                <i className='pt-1 justify-content-end pi pi-print ' style={{ 'fontSize': '1.2em' }} onClick={() => printRegdataList()}></i>
            </div>
            <div className='flex flex-column text-0 p-2 w-full overflow-auto flex-grow-1'>
                {renderListEntries(list)}
            </div>
        </div>
    }

    return <>
        {renderConfirmDialog()}
        {renderDepartment()}
    </>
}