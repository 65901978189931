
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";

export const NavigationPage = () => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true)
    }, [])

    return (
        !mounted ? <></> : <>
            <div className="min-h-screen flex flex-column relative flex-auto">
                <div className="flex justify-content-center m-0 flex-auto w-full" >
                    <div className="p-0 m-0 surface-border overflow-auto w-full" >
                        <Outlet />
                    </div>
                </div>
            </div>

        </>
    );
}