import { useState } from 'react';
import { Button } from 'primereact/button';
import { logout } from '../feathers';
import { ConfirmDialog } from './ConfirmDialog';
import TimeDisplay from './TimeDisplay';


const Headline = ({ buttons }) => {
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

    const renderConfirmDialog = () => {
        return <ConfirmDialog
            handleOnClick={() => logout()}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Ausloggen'
            message='Wollen Sie sich wirklich abmelden?'
            title='FFW-GD'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
    }

    return <div className={"flex w-full justify-content-center px-2 align-items-center headline  border-bottom-1 gap-3 surface-border "} style={{ height: '60px' }}>
        {renderConfirmDialog()}
        <div className='flex flex-column mx-3 text-0 text-lg '>
            <img src={`./icons/FFW-GD-Wappen.png`} className='flex align-items-center my-auto w-2rem' />
        </div>
        <div className='flex flex-column text-0 text-lg '>
            <div className='flex justify-content-start'>Freiwillige Feuerwehr</div>
            <div className='flex justify-content-start'>Schwäbisch Gmünd</div>
        </div>
        <div className='flex flex-column mx-3 text-0 text-lg flex-grow-1'>

        </div>


        {buttons.map(e => <Button key={e.key} className='bg-white text-900' label={e.label} onClick={e.command} />)}
        <div className='flex flex-column mx-3 text-0 '>
            <i className='pi pi-power-off text-2xl ' onClick={() => { setDisplayConfirmDialog(true) }} />
        </div>
        <TimeDisplay />
    </div>

};

export default Headline;