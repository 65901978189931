import React, { useContext, useState, useEffect } from "react";
import client, { getData } from '../feathers'
import { LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";

const OrganizationFunctionsContext = React.createContext();
const OrganizationFunctionsSelectionContext = React.createContext();

const servicename = ENUM_SERVICES.ORGANIZATIONFUNCTIONS;
const listenernames = ['created', 'patched'];
const providername = 'OrganizationFunctionsProvider';

export function useOrganizationFunctionsList() {
    return useContext(OrganizationFunctionsContext);
}
export function useOrganizationFunctionsSelectionList() {
    return useContext(OrganizationFunctionsSelectionContext);
}

export function OrganizationFunctionsProvider({ children }) {
    const [list, setList] = useState([]);
    const [listSelection, setListSelection] = useState([]);
    const [mounted, setMounted] = useState(false)

    const getOrganizationFunctionsList = async () => {
        await getData(servicename).then((list) => {
            const sortedList = list;//getSortedList(list, 'name')
            setList(sortedList);
            setListSelection(sortedList.filter(e => e.active === 1))

            LoggerContext.info(`########### ${providername} useEffect found:${list.length}`);
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const removeListener = () => {
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
            service.on(listener, getOrganizationFunctionsList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            getOrganizationFunctionsList()
            return () => { LoggerContext.debug(`########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    return (
        <OrganizationFunctionsContext.Provider value={list}>
            <OrganizationFunctionsSelectionContext.Provider value={listSelection}>
                {children}
            </OrganizationFunctionsSelectionContext.Provider>
        </OrganizationFunctionsContext.Provider>
    )
}