import React, { useContext, useState, useEffect } from "react";
import client, { getData } from '../feathers'
import { logContext, logDefault, LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { format } from "date-fns";

const MainMonitorContext = React.createContext();
const servicename = ENUM_SERVICES.MAINMMONITOR;
const listenernames = ['created', 'patched'];
const providername = 'MainMonitorProvider';

export function useMainMonitorList() {
    return useContext(MainMonitorContext);
}
export function MainMonitorProvider({ children }) {
    const [list, setList] = useState(null);
    const [mounted, setMounted] = useState(false)

    const getMainMonitorList = async () => {
        console.log('getMainMonitorList: ' + format(new Date(), 'yyyy-MM-dd HH:mm:ss'))
        await getData(servicename).then((list) => {
            setList(list);
            logContext(providername, 'info', `########### ${providername} useEffect found:${list.length}`)
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const removeListener = () => {
        const serviceDepartments = client.service(ENUM_SERVICES.DEPARTMENTS);
        const serviceRegData = client.service(ENUM_SERVICES.REGDATA);
        listenernames.forEach((listener) => {
            serviceDepartments.removeListener(listener);
            serviceRegData.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const serviceDepartments = client.service(ENUM_SERVICES.DEPARTMENTS);
        const serviceRegData = client.service(ENUM_SERVICES.REGDATA);
        listenernames.forEach((listener) => {
            serviceDepartments.removeListener(listener);
            serviceRegData.removeListener(listener);
            serviceDepartments.on(listener, getMainMonitorList);
            serviceRegData.on(listener, getMainMonitorList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            getMainMonitorList()
            return () => { LoggerContext.info(`########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    return (
        <MainMonitorContext.Provider value={list}>
            {children}
        </MainMonitorContext.Provider>
    )
}