import React, { useContext, useState, useEffect } from "react";
import client, { getData } from '../feathers'
import { logContext, LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";
import { differenceInSeconds, formatDistance, parse, parseISO } from "date-fns";
import { de } from "date-fns/locale";

const TerminalContext = React.createContext();

const servicename = ENUM_SERVICES.TERMINALS;
const listenernames = ['created', 'patched'];
const providername = 'TerminalProvider';

export function useTerminalList() {
    return useContext(TerminalContext);
}

export function TerminalProvider({ children }) {
    const [list, setList] = useState([]);
    const [terminalList, setTerminalList] = useState([]);
    const [mounted, setMounted] = useState(false)

    const getTerminalList = async () => {
        await getData(ENUM_SERVICES.TERMINALS).then(async (terminalList) => {
            await getLastAlifeList(terminalList)
            logContext(providername, 'info', `########### ${providername} getTerminalList found:${terminalList.length}`);
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const getLastAlifeList = async (terminalListInit) => {
        await getData(ENUM_SERVICES.LASTALIFE).then((lastAlifelist) => {
            const terminals = terminalListInit || terminalList
            for (let terminal of terminals) {
                const lastAlife = lastAlifelist.find((e) => e.terminalKey === terminal.serialnumber)
                terminal.lastAlife = lastAlife ? lastAlife.time : (terminal.lastAlife || parse('2024-01-01', 'yyyy-MM-dd', new Date()).getTime())
                terminal.secondsOverdue = differenceInSeconds(Date.now(), terminal.lastAlife) - terminal.alifeDelayInSeconds
                terminal.overdueTime = ''
                if (terminal.secondsOverdue > 0) {
                    terminal.overdueTime = formatDistance(Date.now(), terminal.lastAlife, {
                        locale: de
                    })
                }
            }
            setTerminalList(getSortedList(terminals, 'name'));
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const removeListener = () => {
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const service = client.service(servicename);
        const serviceTerminal = client.service(ENUM_SERVICES.LASTALIFE);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
            serviceTerminal.removeListener(listener);
            service.on(listener, getTerminalList);
            serviceTerminal.on(listener, getTerminalList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            getTerminalList()
            const timeIntervalRefresh = window.setInterval(() => getTerminalList(), 30000);
            return () => { logContext(providername, 'debug', `########### ${providername} ---- unload`); removeListener(); window.clearInterval(timeIntervalRefresh); }
        }
    }, [mounted])

    return (
        <TerminalContext.Provider value={terminalList}>
            {children}
        </TerminalContext.Provider>
    )
}