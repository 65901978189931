import React, { useContext, useState, useEffect } from "react";
import client, { getData } from '../feathers'
import { LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";

const OperativeFunctionsContext = React.createContext();
const SOperativeFunctionselectionContext = React.createContext();

const servicename = ENUM_SERVICES.OPERATIVEFUNCTIONS;
const listenernames = ['created', 'patched'];
const providername = 'OperativeFunctionsProvider';

export function useOperativeFunctionsList() {
    return useContext(OperativeFunctionsContext);
}
export function useOperativeFunctionsSelectionList() {
    return useContext(SOperativeFunctionselectionContext);
}

export function OperativeFunctionsProvider({ children }) {
    const [list, setList] = useState([]);
    const [listSelection, setListSelection] = useState([]);
    const [mounted, setMounted] = useState(false)

    const getOperativeFunctionsList = async () => {
        await getData(servicename).then((list) => {
            const sortedList = getSortedList(list, 'name')
            setList(sortedList);
            setListSelection(sortedList.filter(e => e.active === 1))
            LoggerContext.info(`########### ${providername} useEffect found:${list.length}`);
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const removeListener = () => {
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
            service.on(listener, getOperativeFunctionsList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            getOperativeFunctionsList()
            return () => { LoggerContext.debug(`########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    return (
        <OperativeFunctionsContext.Provider value={list}>
            <SOperativeFunctionselectionContext.Provider value={listSelection}>
                {children}
            </SOperativeFunctionselectionContext.Provider>
        </OperativeFunctionsContext.Provider>
    )
}