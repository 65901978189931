import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

const TimeDisplay = () => {
    const [currentTime, setCurrentTime] = useState(new Date());


    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(timer); // Cleanup the interval on component unmount
    }, []);


    return <div className='flex flex-column mx-3 text-0 text-lg '>
        <div className='flex justify-content-end'>{format(currentTime, 'EEEEEE dd.MM.yyyy', { locale: de })}</div>
        <div className='flex justify-content-end'>{format(currentTime, 'HH:mm:ss', { locale: de })}</div>
    </div>


};

export default TimeDisplay;