import React, { useContext, useState, useEffect } from "react";
import client, { getData } from '../feathers'
import { LoggerContext } from "../logger";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { getSortedList } from "../snippets";

const DepartmentContext = React.createContext();
const DepartmentSelectionContext = React.createContext();

const servicename = ENUM_SERVICES.DEPARTMENTS;
const listenernames = ['created', 'patched'];
const providername = 'DepartmentProvider';

export function useDepartmentList() {
    return useContext(DepartmentContext);
}
export function useDepartmentSelectionList() {
    return useContext(DepartmentSelectionContext);
}

export function DepartmentProvider({ children }) {
    const [list, setList] = useState([]);
    const [listSelection, setListSelection] = useState([]);
    const [mounted, setMounted] = useState(false)

    const getDepartmentList = async () => {
        await getData(servicename).then((list) => {
            setList(getSortedList(list, 'name'));
            LoggerContext.info(`########### ${providername} useEffect found:${list.length}`);
        }).catch((error) => { LoggerContext.error("Error " + providername, error) });
    }

    const removeListener = () => {
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
        })
    }

    useEffect(() => {
        setMounted(true)
        const service = client.service(servicename);
        listenernames.forEach((listener) => {
            service.removeListener(listener);
            service.on(listener, getDepartmentList);
        })
    }, [])

    useEffect(() => {
        if (mounted) {
            getDepartmentList()
            return () => { LoggerContext.debug(`########### ${providername} ---- unload`); removeListener(); }
        }
    }, [mounted])

    return (
        <DepartmentContext.Provider value={list}>
            <DepartmentSelectionContext.Provider value={listSelection}>
                {children}
            </DepartmentSelectionContext.Provider>
        </DepartmentContext.Provider>
    )
}