import { ListBox } from "primereact/listbox";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useDepartmentList } from "../Persistence/DepartmentContext";
import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { Multiselect } from "../Components/Multiselect";
import { useOrganizationFunctionsSelectionList } from "../Persistence/OrganizationFunctionsContext";
import { useOperativeFunctionsSelectionList } from "../Persistence/OperativeFunctionsContext";


export const emptyPerson = { id: null, version: 1, active: 1, lastname: '', firstname: '', roles: '', officePhoneNumber: '', rfids: [], organizationFunctions: [], operativeFunctions: [] }

export const PersonForm = ({ formik }) => {
    const departmentList = useDepartmentList();
    const organizationFunctionsList = useOrganizationFunctionsSelectionList();
    const operativeFunctionnsList = useOperativeFunctionsSelectionList();
    const [selectedRfid, setSelectedRfid] = useState('')
    const [deleteRfid, setDeleteRfid] = useState(null)
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

    const selectedTemplateOperative = (option) => {
        const entry = option ? operativeFunctionnsList.find(e => e.id === option) : null;
        return entry ? <div className="w-full text-xm">{entry.name}</div> : '';
    }

    const selectedTemplateOrganization = (option) => {
        const entry = option ? organizationFunctionsList.find(e => e.id === option) : null;
        return entry ? <div className="w-full text-xm">{entry.name}</div> : '';
    }

    const itemTemplateRfid = useCallback((option) => {
        return <div className='flex'>
            <div className='flex flex-grow-1'>{option.rfid}</div>
            {(!selectedRfid || option.rfid !== selectedRfid.rfid) ? '' :
                <i className='flex pi pi-trash' onClick={() => { setDeleteRfid(selectedRfid.rfid); setDisplayConfirmDialog(true) }} />}
        </div>
    }, [selectedRfid])


    return <div className='flex person-form'>
        <ConfirmDialog
            handleOnClick={() => { }}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Löschen'
            message={`Wollen Sie den RFID-Tag ${deleteRfid} wirklich löschen?`}
            title='Löschabfrage RFID-Tag'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
        <div className='flex flex-column col-6 align-content-start'>
            <InputChangesChecked id='firstname' className='mb-0' autoFocus={true} type="text" formik={formik} label='Vorname' setShowDialog={() => { }} />
            <InputChangesChecked id='lastname' tabIndex={0} type="text" formik={formik} label='Nachname' setShowDialog={() => { }} />
            <InputChangesChecked id='officePhoneNumber' tabIndex={0} type="text" formik={formik} label='Telefon' setShowDialog={() => { }} />
            <DropdownChangesChecked
                id='departmentId'
                editmode={true}
                label='Abteilung'
                formik={formik}
                itemLabel='name'
                itemValue='id'
                className={'bg-white w-full mb-3'}
                options={departmentList}
            />
            <div className="flex-grow-1 field px-2 mr-3">
                <div className='flex flex-row pb-2'>
                    <div className='flex flex-grow-1 align-self-end '>RFID-Tags</div>
                    <Button label='RFID-Tag hinzufügen' icon='pi pi-trash' />
                </div>
                <ListBox
                    id='rfids'
                    className=' w-full h-9rem overflow-auto'
                    value={selectedRfid}
                    options={formik.values.rfids}
                    onChange={(e) => setSelectedRfid(e.value)}
                    style={{ width: '15rem' }}
                    optionLabel='rfid'

                    itemTemplate={itemTemplateRfid} />
            </div>

        </div>
        <div className='col flex flex-column align-content-start'>
            <div className='h-10rem mb-2'>
                <Multiselect
                    id='organizationFunctions'
                    editmode={true}
                    label='Organisatorische Rollen'
                    formik={formik}
                    itemLabel='name'
                    itemValue='id'
                    className={'bg-white w-full'}
                    options={organizationFunctionsList}
                    selectedItemTemplate={selectedTemplateOrganization}
                    placeholder='keine Organisatorische Rolle'
                />
            </div>
            <div className='flex-grow-1'>
                <Multiselect
                    id='operativeFunctions'
                    editmode={true}
                    label='Operative Rollen'
                    formik={formik}
                    itemLabel='name'
                    itemValue='id'
                    className={'bg-white w-full'}
                    options={operativeFunctionnsList}
                    selectedItemTemplate={selectedTemplateOperative}
                    placeholder='keine Operative Rollen'
                />
            </div>
        </div>

    </div>
}

export const validatePerson = (data) => {
    let errors = {};

    if (!data.lastname) {
        errors.lastname = 'Nachname muss gesetzt sein';
    }
    if (!data.firstname) {
        errors.firstname = 'Vorname muss gesetzt sein';
    }
    if (!data.departmentId) {
        errors.departmentId = 'Abteilung muss gesetzt sein';
    }
    return errors;
}