import { ConfirmDialog } from './ConfirmDialog'
interface DialogBoxProps {
    showDialog: boolean
    cancelNavigation: any
    confirmNavigation: any
}

const UnsafedChangesDialog: React.FC<DialogBoxProps> = ({
    showDialog,
    cancelNavigation,
    confirmNavigation,
}) => {
    return (
        <ConfirmDialog
            title='Ungespeicherte Änderungen vorhanden'
            message='Seite trotzdem verlassen?'
            labelOk='Ja'
            labelCancel='Nein'
            handleOnReject={cancelNavigation}
            handleOnClick={confirmNavigation}
            setDisplayConfirmDialog={() => { }}
            displayConfirmDialog={showDialog}
            className={undefined}
            style={{}} />
    )
}
export default UnsafedChangesDialog