import React, { useContext, useState, useEffect, useCallback } from "react";
import client, { getData, logout } from '../feathers';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useCurrentUser, useCurrentUserUpdate } from "./CurrentUserContext";
import { isAfter, differenceInSeconds, parseISO, addMinutes, format, addHours, addDays } from 'date-fns'
import { NotConnectedError, ReauthenticationError } from '../errorClasses'
import { useSocketError, useSocketErrorUpdate } from "./SocketErrorContext";
import { LoggerLogin, logContext } from "../logger";

export const INACTIVE_INTERVALL = 600;        // in seconds
export const REAUTHENTICATION_INTERVALL = 30; // in seconds


const LoginContext = React.createContext();
const LoginUpdateContext = React.createContext();
const ReauthenticateContext = React.createContext();

const providername = 'LoginProvider';
let lastAction = new Date();

export function useLogin() {
	return useContext(LoginContext);
}
export function useLoginUpdate() {
	return useContext(LoginUpdateContext);
}
export function useReauthenticate() {
	return useContext(ReauthenticateContext);
}

export const updateLastAction = () => {
	lastAction = new Date();
}

let timeIntervalExpire;

export function LoginProvider({ children }) {
	const [login, setLogin] = useState();
	const [reAuthenticate, setReAuthenticate] = useState(true);
	const currentUser = useCurrentUser();
	const setCurrentUser = useCurrentUserUpdate();
	const socketError = useSocketError();
	const successHandler = useSocketErrorUpdate();

	const updateLogin = useCallback((login) => {

		if (!login) {
			setCurrentUser(null);
			reactLocalStorage.remove('refreshToken');
		} else if (!currentUser) {
			getData('users', login.user.id).then((user) => {
				setCurrentUser(user);
			})

		}
		setLogin(login);
	}, [currentUser, setCurrentUser]);

	const authenticate = useCallback(() => {
		try {
			const refreshToken = reactLocalStorage.get('refreshToken');
			const userInactiveIntervall = parseInt(reactLocalStorage.get('userInactiveIntervall', '' + INACTIVE_INTERVALL));
			const keepAlive = reactLocalStorage.get('keepAlive', 'true') === 'true';
			const refrehRequest = { strategy: 'jwt', "action": "refresh", refresh_token: refreshToken }
			if (!keepAlive) {
				refrehRequest.expiresIn = (parseInt(userInactiveIntervall) + 'M')
			}

			logContext(providername, 'info', `authenticate: refresh_token =${refreshToken !== undefined}\nexpiredIn = ${refrehRequest.expiresIn}\nkeepAlive = ${keepAlive}`);

			if (!refreshToken) {
				return logout(currentUser);
			}
			client.authenticate(refrehRequest).catch(async (error) => {
				LoggerLogin.error("ERROR authentication fehlgeschlagen: " + error.message);
				return client.logout();
			});
		} catch (error) {
			client.logout();
			if (error.message === "Invalid token specified" || error.name === "NotAuthenticated") {
				throw new ReauthenticationError(error)
			} else if (error.className === "timeout") {
				throw new NotConnectedError(error)
			} else {
				throw new Error(error.message, error)
			}
		}
	}, [])

	const checkAuthentication = useCallback(() => {

		const secondsLastAction = differenceInSeconds(new Date(), lastAction)
		const expireTime = parseISO(reactLocalStorage.get('expireTime', '2000-01-01T00:00:00Z'));
		const keepAlive = reactLocalStorage.get('keepAlive', 'true') === 'true';
		const userInactiveIntervall = parseInt(reactLocalStorage.get('userInactiveIntervall', '' + INACTIVE_INTERVALL));
		const inactive = (secondsLastAction > userInactiveIntervall);
		const expires = isAfter(new Date(), expireTime);
		//const expiresCheck = isAfter(new Date(), addMinutes(expireTime, -2));
		const expiresCheck = keepAlive ? isAfter(new Date(), addDays(expireTime, -29)) : isAfter(new Date(), addDays(expireTime, -29));

		logContext(providername, 'info', `checkAuthentication: expires = ${expires}\nexpiresCheck = ${expiresCheck} (${format(addDays(expireTime, -1), 'yyyy-MM-dd HH:mm')})\ninactive = ${inactive}\nsecondsLastAction = ${secondsLastAction}\nuserInactiveIntervall = ${userInactiveIntervall}\nkeepAlive = ${keepAlive}\naccessToken expires date = ${format(expireTime, 'yyyy-MM-dd HH:mm')}`)
		if (expires && keepAlive) {
			setReAuthenticate(true);
		} else if (expiresCheck && (keepAlive || !inactive)) {
			setReAuthenticate(true);
		} else if (expires && !keepAlive) {
			logout(currentUser);
		} else if (inactive && !keepAlive) {
			logout(currentUser);
		}
	}, []);

	useEffect(() => {
		timeIntervalExpire = window.setInterval(checkAuthentication, REAUTHENTICATION_INTERVALL * 1000);
		return () => { LoggerLogin.debug('clear Inteval timeIntervalExpire & eventListener'); window.clearInterval(timeIntervalExpire); }
	}, [checkAuthentication]);

	useEffect(() => {
		if (reAuthenticate) {
			authenticate();
		}
	}, [reAuthenticate, authenticate]);

	useEffect(() => {
		//LoggerLogin.info(`#### ${providername} useEffect socketError changed ${socketError}`);
		if (socketError === null) {
			// Try to authenticate with the JWT stored in localStorage
			client.authenticate().catch((error) => {
				updateLogin(null)
				if (error.className !== "NotConnectedError") {
					LoggerLogin.error('##### REAUTHENTICATE ON Application START ###### ', error)
					checkAuthentication();
				}
			});
		}
	}, [socketError, checkAuthentication, updateLogin]);

	useEffect(() => {
		//LoggerLogin.info(`#### ${providername} useEffect currentUser changed ${currentUser ? currentUser.displayname : 'NULL'}`);
		// On successfull login
		client.removeAllListeners();
		client.on('authenticated', _login => {
			const { refreshToken, expireTimeAccessToken, expireTimeRefreshToken } = _login
			setReAuthenticate(false);
			reactLocalStorage.set('expireTime', expireTimeAccessToken);
			if (refreshToken) {
				reactLocalStorage.set('refreshToken', refreshToken);
				reactLocalStorage.set('expireTimeRefreshToken', expireTimeRefreshToken);
			}
			//LoggerLogin.info(`#### CLIENT AUTHENTICATED refreshToken = ${refreshToken !== undefined}; expire access token = ${expireTimeAccessToken}; expire refresh token = ${expireTimeRefreshToken}`);

			if (socketError) {
				successHandler(null);
			}
			updateLogin(_login);
		});

		// On logout reset all all local state (which will then show the login screen)
		client.on('logout', () => { LoggerLogin.error(`#### ${providername} client.on logout invoked ####`); window.clearInterval(timeIntervalExpire); updateLogin(null); });
	}, [currentUser, socketError, successHandler, updateLogin])

	return (
		<LoginContext.Provider value={login}>
			<LoginUpdateContext.Provider value={updateLogin}>
				<ReauthenticateContext.Provider value={checkAuthentication}>
					{children}
				</ReauthenticateContext.Provider>
			</LoginUpdateContext.Provider>
		</LoginContext.Provider>
	)
}