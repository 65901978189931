import { FeathersError } from '@feathersjs/errors';
import { format } from 'date-fns'

export class PersistenceError extends FeathersError {
	constructor(classname, subject, message, error, path, data) {
		super(subject, "service: " + path, 415, classname, data);
		if (error) {
			const { errors } = error
			if (errors && errors.length > 0) {
				this.message = this.message + ":<br/>" + errors.map((e) => e.message + "; ")
			} else {
				this.message = this.message + ":<br/>" + error.message;
			}
		}
		console.error(format(new Date(), 'yyyy-MM-dd HH:mm:ss'), this.message, this);
	}
}

export class ReauthenticationError extends PersistenceError {
	constructor(error) {
		super('ReauthenticationError', 'Ihre Anmeldung ist abgelaufen. Bitte melden Sie sich erneut an!', error.message, error, 'authentication');
	}
}

export class NotConnectedError extends PersistenceError {
	constructor(error) {
		super('NotConnectedError', 'Keine Verbindung zum Server!', error.message + 'Bitte kontaktieren Sie den Administrator!', error, 'connection');
	}
}

export class UserPatchError extends PersistenceError {
	constructor(error, data) {
		super('UserPatchError', 'Fehler beim Anlegen/Aktualisieren des Anmeldeusers', error.message, error, 'users', data);
	}
}

export class CreateDataError extends PersistenceError {
	constructor(message, error, path, data) {
		if (error.className === 'forbidden') {
			super('ForbiddenError', 'Sie haben keine Berechtigung zum Aktualisieren dieser Daten!', message, error, path, data);
		} else {
			super('CreateDataError', 'Fehler beim Anlegen von Daten', message, error, path, data);
		}
	}
}

export class PatchDataError extends PersistenceError {
	constructor(message, error, path, data) {
		if (error.className === 'forbidden') {
			super('ForbiddenError', 'Sie haben keine Berechtigung zum Aktualisieren dieser Daten!', message, error, path, data);
		} else {
			super('PatchDataError', 'Fehler beim Aktualisieren von Daten', message, error, path, data);
		}
	}
}

export class GetDataError extends PersistenceError {
	constructor(message, error, path, data) {
		super('GetDataError', 'Fehler beim Holen eines Datensatzes', message, error, path, data);
	}
}

export class FindDataError extends PersistenceError {
	constructor(message, error, path, data) {
		super('FindDataError', 'Fehler beim Holen von Daten', message, error, path, data);
	}
}

export class DeleteDataError extends PersistenceError {
	constructor(message, error, path, data) {
		super('DeleteDataError', 'Fehler beim Löschen eines Datensatzes', message, error, path, data);
	}
}