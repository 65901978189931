import pdfFonts from "pdfmake/build/vfs_fonts";
import { fetchIconsBase64, getData } from "../feathers";
import { format } from "date-fns";
import { logDefault } from "../logger";

//eslint-disable-next-line @typescript-eslint/no-var-requires
const lineColor = '#D8DCE0';
const color = '#494C4F';
const fontSize_XS = 8;
const fontSize_S = 10;
const fontSize = 12;
const fontSize_M = 14;
const fontSize_L = 16;

const defaultStyles = { // change the default styles
    h1: { fontSize: fontSize_M },
    h2: { fontSize: fontSize },
    p: { margin: [0, 1, 0, 1] },
    ol: { marginBottom: 5 },
    li: { marginLeft: 30 },
}
const styles = {
    headerdate: { fontSize: fontSize_XS, color },
    tableHeader: { fontSize: fontSize, bold: true, color: '#333333', fillColor: '#71A5D8', },
    tableCell: { fontSize: fontSize_S, color },
    tableBody: { fontSize: fontSize_S, color },
    smallerText: { fontSize: fontSize_S, color },
    largerText: { fontSize: fontSize_L },
    normalText: { fontSize: fontSize },
    normalTextBold: { fontSize: fontSize, bold: true, color },
    headline: {
        bold: true,
        fontSize: fontSize,
        color,
        margin: [0, 10, 0, 5]
    },
};

const tableHeadlineLayout = {
    hLineWidth: function (i, node) { return 1 }, vLineWidth: function (i, node) { return 0 }, hLineColor: function (i, node) { return lineColor }
}

async function createPDF(department, regdataList, alarmDate) {

    try {
        const images = await fetchIconsBase64();
        const logo = images.filter((entry) => entry.alias === 'Logo-FFW-GD')[0].base64;
        let header = null;
        let content = [];

        const title = `Freiwillige Feuerwehr Schwäbisch Gmünd\nAlarmliste: ${department.name}`
        const subtitle = `Start: ${format(alarmDate || new Date(), 'dd.MM.yyyy HH:mm')}     Ende: ${format(alarmDate || new Date(), 'dd.MM.yyyy HH:mm')}`
        header = createPDFHeader(logo, title, subtitle);
        content = [{ text: 'Thema:', style: 'normalTextBold' }]
        content.push(createTableSheet(regdataList))
        const pdfMake = require('pdfmake');
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        const docDefinition = {
            defaultStyles, styles, pageMargins: [80, 100, 50, 50],
            info: { title: `Einsatz vom ${format(new Date(), 'yyyy-MM-dd')}`, author: 'FFW-GD' },
            footer: function (currentPage, pageCount, pageSize) {
                return [
                    { text: 'Seite ' + currentPage.toString() + ' / ' + pageCount, alignment: 'right', style: 'headerdate', margin: [0, 20, 25, 0] }]
            },
            header,
            content
        }
        logDefault('exportToPDF', 'info', 'docDefinition', docDefinition)
        pdfMake.createPdf(docDefinition).open();

    } catch (error) {
        if (error) { console.error(error) };
    }
}

function createPDFHeader(logo, title, subtitle) {
    const table = {
        margin: [10, 10, 10, 0], layout: 'noBorders',
        table: {
            widths: [80, '*', 100],
            body: [
                [{}, {}, { text: `Einsatz 2 vom ${format(new Date(), 'yyyy-MM-dd')}`, alignment: 'right', style: 'headerdate' }],
                [{ image: "data:image/png;base64," + logo, alignment: 'right', width: 40 }, { color, alignment: 'center', fontSize: (title && title.length > 150 ? fontSize_S : fontSize), text: title, margin: [0, 10, 0, 0] }, {}],
                [{}, { text: subtitle ? subtitle : '', alignment: 'center', style: { fontSize: fontSize_S, color }, margin: [0, 2, 0, 0] }, {}]
            ]
        }
    }

    return (currentPage, pageCount, pageSize) => {
        return [
            {
                table: {
                    heights: pageSize.height / 2,
                    widths: [15, '*'],
                    body: [[{ border: [false, false, false, true], text: '' }, { border: [false, false, false, false], stack: [table] }]]
                },
            },

        ]
    }
}

function createTableSheet(regdataList) {
    const body = [];
    body.push([{ text: 'ID', style: 'tableHeader' }, { text: 'Name', style: 'tableHeader' }]);
    let counter = 0;
    for (let regdata of regdataList) {
        body.push([++counter, regdata.name]);
    }

    return {
        layout: 'lightHorizotalLines',
        margin: [0, 5, 15, 0],
        table: {
            headerRows: 1,
            widths: [20, '*'],
            body
        }
    }
}

const exportToPDF = async (department, regDataList, alarmDate) => {
    createPDF(department, regDataList, alarmDate);
}

export default exportToPDF;